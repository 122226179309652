@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&family=Roboto:wght@300;400;700&family=Sedgwick+Ave+Display&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}
*::-webkit-scrollbar { 
    display: none;
}
body{
    font-family: 'Roboto', sans-serif;
}
a{
    font-size: black;
    text-decoration: none;
}
.apexcharts-toolbar{
    display: none !important;
}

#ImgUpload > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0%;
    cursor: pointer;
    z-index: 5;
}

.rdw-editor-main{
    height: 75% !important;
}

